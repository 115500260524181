/**
 * -------------------------------------------------------
 * Element Plus 基于 Vue 3，面向设计师和开发者的组件库 see https://element-plus.gitee.io/
 * -------------------------------------------------------
 */
import type { App } from '@/plugins/types';
import ElementPlus from 'element-plus';
// 注册所有 @element-plus/icons-vue 图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import 'element-plus/dist/index.css';
// element dark(内置暗黑模式)
import 'element-plus/theme-chalk/dark/css-vars.css';
export default (app: App) => {
  app.use(ElementPlus);
  //注册图标
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
    app.component(`el-icon-${component.name}`, component);
  }
};
