import { defineStore } from 'pinia';
import type { GlobalState, ThemeConfigProp } from './interface';
import { DEFAULT_PRIMARY } from '@/config/server';
import { setting } from '@/api/system/user';
import { ElMessage } from 'element-plus';

// defineStore 调用后返回一个函数，调用该函数获得 Store 实体
export const GlobalStore = defineStore({
  // id: 必须的，在所有 Store 中唯一
  id: 'GlobalState',
  // state: 返回对象的函数
  state: (): GlobalState => ({
    // element组件大小
    assemblySize: 'default',
    // 后台主页面切换动画，可选值<slide-right|slide-left|el-fade-in-linear|el-fade-in|el-zoom-in-center|el-zoom-in-top|el-zoom-in-bottom>
    mainAnimation: 'slide-right',
    // themeConfig
    themeConfig: {
      // 布局切换 ==>  纵向：vertical | 经典：classic | 横向：transverse | 分栏：columns
      layout: 'columns',
      // 默认 primary 主题颜色
      primary: DEFAULT_PRIMARY,
      // 深色模式
      isDark: false,
      // 灰色模式
      isGrey: false,
      // 色弱模式
      isWeak: false,
      // 面包屑导航
      breadcrumb: true,
      // 标签页
      tabs: true,
      // 页脚
      footer: true,
    },
  }),
  getters: {},
  actions: {
    // setToken
    setToken(token: string) {
      this.token = token;
    },
    // setUserInfo
    setUserInfo(userInfo: any) {
      this.userInfo = userInfo;
    },
    // setAssemblySizeSize
    setAssemblySizeSize(assemblySize: string) {
      this.assemblySize = assemblySize;
    },
    // updateLanguage
    updateLanguage(language: string) {
      this.language = language;
    },
    // setThemeConfig
    setThemeConfig(themeConfig: ThemeConfigProp) {
      this.themeConfig = themeConfig;
    },
    setting() {
      setting({ setting: this.themeConfig }).run({
        manual: false,
        onSuccess: (res) => {
          console.log(res);
          ElMessage.success('保存成功！');
        },
      });
    },
  },
  persist: {
    enabled: true,
    strategies: [
      //将所有值存储到user中
      {
        key: 'admin_layout_config',
      },
    ],
  },
});
