// 加密
// 采用 DES

import CryptoJS from 'crypto-js';

const DES_KEY = 'Fpbi5jb2';

const keyHex = CryptoJS.enc.Utf8.parse(DES_KEY);

//DES加密
export function encryptByDES(message: string) {
  const encrypted = CryptoJS.DES.encrypt(message, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.ZeroPadding,
  });
  return encrypted.ciphertext.toString();
}

//DES加密
export function decryptByDES(ciphertext: string) {
  const decrypted = CryptoJS.DES.decrypt(
    {
      ciphertext: CryptoJS.enc.Hex.parse(ciphertext),
    },
    keyHex,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.ZeroPadding,
    },
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
}
// AES加解密
const encryptByAES = (data: string | CryptoJS.lib.WordArray, secretKey: string) => {
  const keyHex = CryptoJS.enc.Utf8.parse(secretKey);
  const result = CryptoJS.DES.encrypt(data, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return result.ciphertext.toString(CryptoJS.enc.Base64);
};

const decryptByAES = (cipher: string | CryptoJS.lib.CipherParams, secretKey: string) => {
  const result = CryptoJS.DES.decrypt(cipher, CryptoJS.enc.Utf8.parse(secretKey), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Utf8.stringify(result);
};

// BASE64
const base64 = {
  en: (data: string) => CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data)),
  de: (data: string) => CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8),
};

//MD5
const md5 = (data: string) => CryptoJS.MD5(data).toString();

export { md5, base64, encryptByAES, decryptByAES };
