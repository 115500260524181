import type { UserInfo } from '@/types/user';
export const useUserStore = defineStore('userStore', {
  //初始化
  state: () => {
    // token
    const token = '';
    // userInfo
    const userInfo = {
      user_id: 0,
      dept_id: 0,
      login_name: 'xzusoft',
      nick_name: '讯洲',
      email: 'admin@xzusoft.com',
      mobile: '18600008888',
      sex: 1,
      avatar: '',
      login_date: '',
      login_ip: '127.0.0.1',
      tip: '',
      dept_text: '',
      post_text: [
        {
          post_id: 0,
          post_name: '',
        },
      ],
    };
    return { token, userInfo };
  },
  //方法
  actions: {
    // setToken
    setToken(token: string) {
      this.token = token;
    },
    // setUserInfo
    setUserInfo(userInfo: UserInfo) {
      this.userInfo = userInfo;
    },
  },
  getters: {},
  // 开启数据缓存 see https://seb-l.github.io/pinia-plugin-persist/advanced/strategies.html
  persist: {
    enabled: true,
    strategies: [
      //将所有值存储到user中
      {
        key: 'user',
        storage: localStorage,
      },
      // {
      //   paths: ['token'],
      // },
    ],
  },
});
