{
  "language": "简体",
  "global-hello": "你好，世界-中国-Global!",
  "hello": "你好，世界，我是中国",
  "tabs": {
    "more": "更多",
    "refresh": "刷新当前",
    "closeCurrent": "关闭当前",
    "closeOther": "关闭其它",
    "closeAll": "关闭所有"
  }
}
