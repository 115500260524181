import { defineStore } from 'pinia';
import type { AuthState } from '@/stores/interface';
import { TABS_BLACK_LIST } from '@/config/index';

// AuthStore
export const AuthStore = defineStore({
  id: 'AuthState',
  state: (): AuthState => ({
    // 用户按钮权限列表
    authButtons: [],
    // 路由权限列表
    authRouter: [],
  }),
  getters: {
    // 处理权限按钮数据，用于方便控制按钮
    authButtonsArr: (state) => {
      return state.authButtons;
    },
    // 后台返回的菜单数据，用于方便控制路由跳转时权限（这里已经处理成一维数组了）
    dynamicRouter: (state) => {
      return state.authRouter;
    },
  },
  actions: {
    // setAuthButtons
    async setAuthButtons(authButtonList: string[]) {
      this.authButtons = authButtonList;
    },
    // setAuthRouter
    async setAuthRouter(dynamicRouter: string[]) {
      let arr = Array.from(new Set(dynamicRouter));
      // 添加白名单
      arr = arr.concat(TABS_BLACK_LIST);
      this.authRouter = arr;
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'AuthState',
        storage: localStorage,
      },
    ],
  },
});
