import type { UserInfo, LoginParams } from '@/types/user';

import api, { METHOD, type IDataType } from '@/api';

//登录接口数据返回
interface LoginResult {
  /** token */
  token: string;
  /** 用户信息 */
  userinfo: UserInfo;
}
//文件上传数据返回
interface uploadResult {
  name: string;
  url: string;
}
export const fileUpload = (data?: any) => {
  //文件上传
  return api.use<IDataType<uploadResult>>({
    url: '/system/upload',
    method: METHOD.POST,
    data,
  });
};
//账号登录
export const Login = (data?: LoginParams) => {
  return api.use<IDataType<LoginResult>, [LoginParams]>({
    url: '/user/login',
    method: METHOD.POST,
    data,
  });
};
//注销登录
export const Logout = () => {
  return api.use<IDataType>({
    url: '/user/logout',
    method: METHOD.POST,
  });
};

interface registerParams {
  mobile: string;
  nick_name: string;
  password: string;
  sms_code: string;
}

//注销登录
export const register = (data?: registerParams) => {
  return api.use<IDataType, [registerParams]>({
    url: '/user/register',
    method: METHOD.POST,
    data,
  });
};

interface qrcodeParams {
  mobile: string;
}

// 获取公众号参数二维码
export const qrcode = (data?: qrcodeParams) => {
  return api.use<IDataType, [qrcodeParams]>({
    url: '/user/official/qrcode',
    method: METHOD.POST,
    data,
  });
};

interface ITestParams {
  page?: string;
  pageSize?: string;
  date_type?: string;
  start_timeal?: string;
  end_time?: string;
}
interface ITestResult {
  list: {
    code: string;
    result: string;
    msg: string;
  }[];
}
// 资源选择
export const attachmentTypeGetList = (data?: ITestParams) => {
  return api.use<IDataType<ITestResult>, [ITestParams]>({
    url: '/system/attachmenttype/getlist',
    method: METHOD.POST,
    data,
  });
};
interface attachmentTypeAddParams {
  name: string;
  type: number;
  status: number;
}
// 添加分组
export const attachmentTypeAdd = (data?: attachmentTypeAddParams) => {
  return api.use<IDataType<ITestResult>, [attachmentTypeAddParams]>({
    url: '/system/attachmenttype/add',
    method: METHOD.POST,
    data,
  });
};
interface attachmentTypeDelParams {
  id: number;
}
// 删除分组
export const attachmentTypeDelete = (data?: attachmentTypeDelParams) => {
  return api.use<IDataType<ITestResult>, [attachmentTypeDelParams]>({
    url: '/system/attachmenttype/delete',
    method: METHOD.POST,
    data,
  });
};
interface attachmentTypeUpdateParams {
  id: string;
  name: string;
  type: number;
  status: number;
}
//更新数据
export const attachmentTypeUpdate = (data?: attachmentTypeUpdateParams) => {
  return api.use<IDataType<ITestResult>, [attachmentTypeUpdateParams]>({
    url: '/system/attachmenttype/update',
    method: METHOD.POST,
    data,
  });
};
interface attachmentGetListParams {
  page?: number;
  pageSize?: number;
  date_type?: string;
  start_timeal?: string;
  end_time?: string;
  material_id: number;
}
export const attachmentGetList = (data?: attachmentGetListParams) => {
  return api.use<IDataType<ITestResult>, [attachmentGetListParams]>({
    url: '/system/attachment/getlist',
    method: METHOD.POST,
    data,
  });
};
export const attachmentDelete = (data?: attachmentTypeDelParams) => {
  return api.use<IDataType<ITestResult>, [attachmentTypeDelParams]>({
    url: '/system/attachment/delete',
    method: METHOD.POST,
    data,
  });
};
interface attachmentUpdateParams {
  id: string;
  material_id: string;
  size: string;
  path: string;
  name: string;
  mime: string;
  storage: string;
  type: string;
  status: string;
}
export const attachmentUpdate = (data?: attachmentUpdateParams) => {
  return api.use<IDataType<ITestResult>, [attachmentUpdateParams]>({
    url: '/system/attachment/update',
    method: METHOD.POST,
    data,
  });
};
// 获取菜单
export const userAuth = () => {
  return api.use<IDataType<ITestResult>>({
    url: '/system/user/auth_list',
    method: METHOD.POST,
  });
};

// 验证码
export const getCaptcha = () => {
  return api.use<IDataType<ITestResult>>({
    url: '/verify/code/image',
    method: METHOD.POST,
  });
};

// 校验当前登录状态
export const getCheck = () => {
  return api.use<IDataType<ITestResult>>({
    url: '/user/check/login',
    method: METHOD.POST,
  });
};

interface getDictParams {
  key: string;
}

// 获取字典
export const getDict = (data?: getDictParams) => {
  return api.use<IDataType<ITestResult>, [getDictParams]>({
    url: '/general/get_dict',
    method: METHOD.POST,
    data,
  });
};
