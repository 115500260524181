// service 实例化统一出口
import axios, { type AxiosRequestHeaders } from 'axios';
import { BASE_URL, TIME_OUT, HEADERS, TOKEN_NAME, APP_SECRET } from '@/config/server';
import AxiosRequest from './request';
import { encryptByAES } from '@/utils/crypto';
import { bulidSign } from '@/utils/sign';
import { useUserStore } from '@/stores/user';
import { router } from '@/plugins/components/vue3-router';
import { ElMessage } from 'element-plus';
import { TabsStore } from '@/stores/module/tabs';
// 用于存储请求的标识，便于路由切换时取消请求(仅取消请求不返回响应，并不会截断响应，即前端取消了请求, 实质后端还是会响应的)
const cancelTokenStore: any = {
  source: {
    token: null,
    cancel: null,
  },
};

/**
 * 实例化AxiosRequest
 */
const axiosRequest = new AxiosRequest({
  baseURL: BASE_URL || '', //全局URL根 */
  timeout: TIME_OUT || 0, // 超时时间，单位毫秒

  // withCredentials: true, //注意：允许跨域携带cookie设为true时，后端 Access-Control-Allow-Origin 不能设置为 " * ", 必须指定请求来源地址

  // 配置本实例拦截器
  interceptors: {
    requestFulfilled: (config) => {
      const { t } = useLang();
      const { token } = useUserStore();
      config.timeoutErrorMessage = t('server.timeout');
      config.cancelToken = cancelTokenStore.source.token;

      config.headers = Object.assign(config.headers as AxiosRequestHeaders, HEADERS);

      config.headers['Content-Type'] = (
        config?.headers && 'Content-Type' in config.headers ? config.headers['Content-Type'] : 'application/json'
      ) as string;

      // 携带token传值给Authorization
      // if (localStorage.getItem('token')) {
      // }

      config.headers[TOKEN_NAME] = token;

      // 请求时间戳
      config.headers['timestamp'] = Date.parse(new Date().toString()) / 1000;

      // 参与签名数据对象
      let signDataObj = {};
      const apiUrl = config.url || '';

      //判断是否是formData
      if (config.data instanceof FormData) {
        config.data.forEach(function (val, key) {
          //TODO 通过配置来设置formData提交时不参与签名的字段
          if (key != 'files') {
            signDataObj[key] = val;
          }
        });
      } else {
        //合并加密参数
        signDataObj = config.data || {};
        if (apiUrl !== '' && apiUrl.indexOf('?') !== -1) {
          const query = apiUrl.split('?')[1].split('&');
          for (let i = 0; i < query.length; i + 1) {
            const key = query[i].split('=')[0];
            const value = query[i].split('=')[1];
            signDataObj[key] = value;
          }
        }
      }
      //计算签名
      config.headers['sign'] = bulidSign(config.headers['timestamp'], signDataObj);
      // TODO 上传无需格式化, 上传时指定文件类型
      if (apiUrl.split('/').pop() === 'upload') {
        // 上传Content-Type设置
        config.headers['Content-Type'] = 'multipart/form-data;charse=UTF-8';
        return config;
      }
      //加密请求参数
      config.data = encryptByAES(JSON.stringify(config.data), APP_SECRET);
      return config;
    },
    requestRejected: (err) => {
      console.log('http-err-1', err);
      return Promise.reject(err);
    },
    responseFulfilled: (res) => {
      if (res.data.code !== 200) {
        ElMessage({
          message: res.data.msg || '系统异常~',
          grouping: true,
          type: 'error',
        });
      }
      return res;
    },
    responseRejected: (err) => {
      console.log('http-err', err.response);
      if (err.response.status === 401) {
        const userStore = useUserStore();
        const tabStore = TabsStore();
        userStore.$reset();
        tabStore.closeMultipleTab();
        router.push({
          path: '/public/login',
        });
      }
      return Promise.reject(err);
    },
  },
});

/**
 * 清空本实例所有请求（通常在路由跳转时调用）
 */
export const clearAllPending = () => {
  const CancelToken = axios.CancelToken;
  cancelTokenStore.source.cancel && cancelTokenStore.source.cancel();
  cancelTokenStore.source = CancelToken.source();
};

/** 常用接口请求方式 */
export const METHOD = axiosRequest.method;

/** 通用标准接口格式, 可修改适合你的后端接口格式 */
export interface IDataType<T = any> {
  code: number;
  msg?: string;
  data: T;
  [key: string]: any;
}

export default axiosRequest;
