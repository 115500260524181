import { md5 } from '@/utils/crypto';
import { APP_SECRET } from '@/config/server';
/** 传入一个对象和布尔值，return根据ASCII码升序或者降序的对象
 * @param {Object} obj
 * @param {Boolean} isAsc true代表升序，false代表降序
 */
const objSortASCII = (obj: { [x: string]: any }, isASC: boolean) => {
  const arr: string[] = [];
  Object.keys(obj).forEach((item) => arr.push(item));

  const sortArr = isASC ? arr.sort() : arr.sort().reverse();
  const sortObj = {};
  for (const i in sortArr) {
    sortObj[sortArr[i]] = obj[sortArr[i]];
  }
  return sortObj;
};
/**
 * 删除数组中或对象中的空值
 * @param {*} obj
 * @returns
 */
const paraFilter = (obj: any) => {
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      if (obj[i] === '' || obj[i] === null || obj[i] === undefined) {
        obj.splice(i, 1);
        i--;
      }
    }
  } else {
    for (const key in obj) {
      if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
    }
  }
  return obj;
};
/**
 * 计算签名
 *
 * @param timestamp 时间戳
 * @param data 请求数据
 * @returns md5 字符串
 */
const bulidSign = (timestamp: number, data: any) => {
  // 签名格式： timestamp + app_secret + data(字典升序)
  const ret = [];
  let body = '';
  if (typeof data === 'string') {
    body = data;
  } else if (typeof data === 'object') {
    data = paraFilter(data);
    data = objSortASCII(data, true);
    for (const it in data) {
      if (typeof data[it] !== 'object' && it !== 'sign') {
        ret.push(`${it}=${data[it]}`);
      } else {
        console.log('JSON data', data[it]);
        ret.push(`${it}=${JSON.stringify(data[it])}`);
      }
    }
    body = ret.join('&');
  }
  const signStr = `app_secret=${APP_SECRET}&${body}&timestamp=${timestamp}`;
  console.log('signStr', signStr);
  // MD5加密并转大写
  return md5(signStr).toUpperCase();
};
export { bulidSign };
