import type { Menu } from '@/types/menu';
import exp from 'constants';
import { ref } from 'vue';
export function isArray(val: any): val is Array<any> {
  return val && Array.isArray(val);
}

/**
 * @description 处理无数据情况
 * @param {String} callValue 需要处理的值
 * @return string
 * */
export function formatValue(callValue: any) {
  // 如果当前值为数组,使用 / 拼接（根据需求自定义）
  if (isArray(callValue)) return callValue.length ? callValue.join(' / ') : '--';
  return callValue ?? '--';
}

/**
 * @description 根据枚举列表查询当需要的数据（如果指定了 label 和 value 的 key值，会自动识别格式化）
 * @param {String} callValue 当前单元格值
 * @param {Array} enumData 枚举列表
 * @param searchProps
 * @param {String} type 过滤类型（目前只有 tag）
 * @return string
 * */
export function filterEnum(callValue: any, enumData: any, searchProps?: { [key: string]: any }, type?: string): string {
  const value = searchProps?.value ?? 'value';
  const label = searchProps?.label ?? 'label';
  let filterData: any = {};
  if (Array.isArray(enumData)) filterData = enumData.find((item: any) => item[value] === callValue);
  if (type == 'tag') return filterData?.tagType ? filterData.tagType : '';
  return filterData ? filterData[label] : '--';
}
/**
 * @description 使用递归处理路由菜单，生成一维数组
 * @param {Array} menuList 所有菜单列表
 * @param {Array} newArr 菜单的一维数组
 * @return array TODO
 */

export function handleRouter(routerList: Menu.MenuOptions[], newArr: string[] = []) {
  routerList.forEach((item: Menu.MenuOptions) => {
    typeof item === 'object' && item.path && newArr.push(item.path);
    item.children && item.children.length && handleRouter(item.children, newArr);
  });
  return newArr;
}
/*
 * @description 获取当前时间
 * @return string
 */
export function getTimeState() {
  // 获取当前时间
  const timeNow = new Date();
  // 获取当前小时
  const hours = timeNow.getHours();
  // 判断当前时间段
  if (hours >= 6 && hours <= 10) return `早上好 ⛅`;
  if (hours >= 10 && hours <= 14) return `中午好 🌞`;
  if (hours >= 14 && hours <= 18) return `下午好 🌞`;
  if (hours >= 18 && hours <= 24) return `晚上好 🌛`;
  if (hours >= 0 && hours <= 6) return `凌晨好 🌛`;
}
/**
 * useWatermark.ts
 */
interface gradientType {
  /** 范围 `0.0` 到 `1.0` https://www.runoob.com/tags/canvas-addcolorstop.html */
  value: number;
  /** 对应 `value` 范围的文本颜色 */
  color: string;
}
interface attr {
  /** 字体，默认 `15px Reggae One` */
  font?: string;
  /** 填充绘制图形的颜色，默认 `rgba(0, 0, 0, 0.15)` */
  fillStyle?: string;
  /** 宽度，默认 `250` */
  width?: number;
  /** 高度，默认 `100` */
  height?: number;
  /** 水印整体的旋转，默认 `-10` */
  rotate?: number;
  /** 水印的 `z-index`，默认 `100000` */
  zIndex?: string;
  /** 开始绘制文本的 `x` 坐标位置（相对于画布） https://www.runoob.com/jsref/met-canvas-filltext.html */
  fillTextWidth?: number;
  /** 开始绘制文本的 `y` 坐标位置（相对于画布） https://www.runoob.com/jsref/met-canvas-filltext.html */
  fillTextHeight?: number;
  /** 绘制文本渐变色，优先级高于 `fillStyle` */
  gradient?: Array<gradientType>;
  /** 创建阴影（四个参数，如下）
   *  第一个填 `shadowBlur`，`必填`，具体设置看：https://www.runoob.com/jsref/prop-canvas-shadowblur.html
   *  第一个填 `shadowColor`，`可选，默认#000000`，具体设置看：https://www.runoob.com/jsref/prop-canvas-shadowcolor.html
   *  第一个填 `shadowOffsetX`，`可选，默认0`，具体设置看：https://www.runoob.com/jsref/prop-canvas-shadowoffsetx.html
   *  第一个填 `shadowOffsetY`，`可选，默认0`，具体设置看：https://www.runoob.com/jsref/prop-canvas-shadowoffsety.html
   */
  shadowConfig?: Array<any>;
  /** 透明度，范围 `0.0`（完全透明） 到 `1.0` */
  globalAlpha?: number;
  /** 是否让水印无法删除，默认`false`，开启后在控制台操作对应的 `Elements` 也无法删除 */
  forever?: boolean;
}
