/** 接口模型(后端通讯接口模型) */
import api, { type IDataType, METHOD } from '@/api';

// 删除应用分组
interface delGroupParams {}

// 返回参数类型声明
interface ITestResult {
  list: {
    code: number;
    result: Array<any>;
    msg: string;
  }[];
}

// 获取用户信息
export const getUser = (data?: delGroupParams) => {
  return api.use<IDataType<ITestResult>, [delGroupParams]>({
    url: '/system/user/center',
    method: METHOD.POST,
    data,
  });
};

interface avatarParams {
  avatar: string;
}
// 更新头像
export const userAvatar = (data?: avatarParams) => {
  return api.use<IDataType<ITestResult>, [avatarParams]>({
    url: '/system/user/avatar',
    method: METHOD.POST,
    data,
  });
};

interface updatePassParams {
  user_id: number;
  oldPassword?: string;
  newPassword?: string;
  password?: string;
  type: number;
}
// 修改密码
export const updatePass = (data?: updatePassParams) => {
  return api.use<IDataType<ITestResult>, [updatePassParams]>({
    url: '/system/user/update_pwd',
    method: METHOD.POST,
    data,
  });
};

interface userAddParams {
  dept_id: string;
  role_id: number[];
  post_id: number[];
  user_id?: string;
  login_name: string;
  nick_name: string;
  user_type: string;
  email?: string;
  mobile?: string;
  sex: number;
  avatar: string;
  password: string;
  status: number;
  remark?: string;
}

//更新用户信息
export const userUpdate = (data?: userAddParams) => {
  return api.use<IDataType<ITestResult>, [userAddParams]>({
    url: '/system/user/update',
    method: METHOD.POST,
    data,
  });
};

interface userGetListParams {
  page?: number;
  pageSize?: number;
  date_type?: string;
  start_timeal?: string;
  end_time?: string;
  dept_id?: number;
  nick_name?: string;
  mobile?: string;
  status?: number;
  store_id?: string;
}
// 用户管理 获取列表
export const userGetList = (data?: userGetListParams) => {
  return api.use<IDataType<ITestResult>, [userGetListParams]>({
    url: '/system/user/getlist',
    method: METHOD.POST,
    data,
  });
};

// 用户管理 添加用户
export const userAdd = (data?: userAddParams) => {
  return api.use<IDataType<ITestResult>, [userAddParams]>({
    url: '/system/user/add',
    method: METHOD.POST,
    data,
  });
};

interface userDeleteParams {
  user_id: number;
}

// 用户管理 添加用户
export const userDelete = (data?: userDeleteParams) => {
  return api.use<IDataType<ITestResult>, [userDeleteParams]>({
    url: '/system/user/delete',
    method: METHOD.POST,
    data,
  });
};

// 用户管理 获取一条数据
export const userGetOne = (data?: userDeleteParams) => {
  return api.use<IDataType<ITestResult>, [userDeleteParams]>({
    url: '/system/user/getone',
    method: METHOD.POST,
    data,
  });
};

// 角色下拉列表
export const roleSelect = () => {
  return api.use<IDataType<ITestResult>>({
    url: '/system/role/select',
    method: METHOD.POST,
  });
};

// 岗位下拉列表
export const postSelect = () => {
  return api.use<IDataType<ITestResult>>({
    url: '/system/post/select',
    method: METHOD.POST,
  });
};

// 部门下拉列表
export const deptGetlist = () => {
  return api.use<IDataType<ITestResult>>({
    url: '/system/dept/getlist',
    method: METHOD.POST,
  });
};

// 用户导出
export const userExport = () => {
  return api.use<IDataType<ITestResult>>({
    url: '/system/user/export',
    method: METHOD.POST,
  });
};

interface avatarParams {
  setting: {
    layout: string;
    primary: string;
    isDark: boolean;
    isGrey: boolean;
    isWeak: boolean;
    breadcrumb: boolean;
    tabs: boolean;
    footer: boolean;
  };
}
// 用户个性化管理
export const setting = (data?: avatarParams) => {
  return api.use<IDataType<ITestResult>, [avatarParams]>({
    url: '/system/user/setting',
    method: METHOD.POST,
    data,
  });
};
