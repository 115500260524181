import { defineStore } from 'pinia';
import type { Menu } from '@/types/menu';
import type { MenuState } from '../interface';
export const menuStore = defineStore('menuStore', {
  //初始化
  state: (): MenuState => ({
    // 当前选中项ID
    menuIndex: 0,
    // 面包屑
    breadList: [],
    // 全部菜单
    menuList: [],
    isCollapse: false,
  }),
  //方法
  actions: {
    // setMenu
    setMenuIndex(index: number) {
      this.menuIndex = index;
    },
    setBreadList(list: string[]) {
      this.breadList.length = 0;
      this.breadList.push(...list);
    },
    clear() {
      this.menuIndex = 0;
      this.breadList = [];
      this.menuList = [];
    },
    setMenuList(menuList: Menu.MenuOptions[]) {
      this.menuList = menuList;
    },
    setCollapse() {
      this.isCollapse = !this.isCollapse;
    },
  },
  getters: {},
  persist: {
    enabled: true,
    strategies: [
      //将所有值存储到user中
      {
        key: 'menu',
        storage: localStorage,
      },
    ],
  },
});
